import React, { Component } from "react"
import Footer from "../components/footer/footer";
import Nav from "../components/nav/nav"
import Subscribe from "../components/subscribe/subscribe"

import NotFound from "../components/not-found/not-found"

class NotFoundPage extends Component {
  
  render() {
    return (
      <div>
        <NotFound />
        <Subscribe />
        <Footer />
        <Nav showLogo={true} isDarkBackground={true} />
      </div>
    )
  }
}

export default NotFoundPage;
