import React from 'react'
import * as styles from './not-found.module.css'
import logo from '../../images/logo-white.svg'

function NotFound() {
    return(
        <div className={styles.wrapper}>
            <img src={logo} alt="Move Dungarvan Logo" className={styles.logo}/>
            <p className={styles.title}>Not Found</p>
            <p className={styles.text}>Sorry, we can't find what you're looking for..</p>
        </div>
    )
}

export default NotFound